import useSWR from 'swr'
import { chartScales, defaultChartScale } from '../helpers/renderChart'

export const defaultCurrency = 'SGD'
export const currencies = ['SGD', 'USD']

export function pricesFilter (prices, scale) {
  const now = new Date()
  const nowSeconds = now.getTime() / 1000 - now.getTimezoneOffset() * 60
  const scaleSeconds = (chartScales.find(c => c.name === scale) || chartScales.find(c => c.name === defaultChartScale)).seconds
  return prices.filter(p => nowSeconds - p.time < scaleSeconds)
}

export function priceMapper (item, resolution = 1) {
  return item.error ? {} : {
    value: item.ask * resolution,
    time: new Date(item.time).getTime() / 1000 - new Date().getTimezoneOffset() * 60
  }
}

export function getPriceEndPoint (currency, chartScale, productPriceResolution = 1, date) {
  const { interval, datapoints, steps } = chartScales.find(c => c.name === chartScale) || chartScales.find(c => c.name === defaultChartScale)
  date = date ? `&toDate=${new Date(date).toISOString()}` : ''
  return currency ? `/api/gb-savings/spot-rate/interval?qtyIntervals=${datapoints + 1}&interval=${interval}&intervalSize=${steps}&spotType=gld&currency=${currency}${date}` : null
}

export function usePriceHistory (currency, chartScale, productPriceResolution) {
  const { data, error } = useSWR([getPriceEndPoint(currency, chartScale, productPriceResolution), false], {
    refreshInterval: 60 * 60000
  })
  return {
    prices: (!data?.spots?.length ? [] : data.spots).map(m => priceMapper(m, productPriceResolution)).reverse(),
    isLoading: !error && !data,
    isError: error
  }
}

export function useLivePrice (currency, chartScale, productPriceResolution = 1) {
  const { interval, datapoints, steps } = chartScales.find(c => c.name === chartScale) || chartScales.find(c => c.name === defaultChartScale)
  const { data, error, mutate } = useSWR([currency ? `/api/gb-savings/spot-rate/interval?qtyIntervals=2&interval=${interval}&intervalSize=${datapoints * steps}&spotType=gld&currency=${currency}` : null, false], {
    refreshInterval: 60000
  })
  let price = {}
  let updown = 0

  if (data?.spots?.length >= 2) {
    price = priceMapper(data.spots[0], productPriceResolution)
    const lastPrice = priceMapper(data.spots[1], productPriceResolution)
    updown = price.value - lastPrice.value
  }

  return {
    price,
    updown,
    lockInPeriod: data?.variables?.lockTokenExpiry || 30,
    products: data?.products || { CGT: {} },
    maxUnfundedAmount: data?.variables?.maxUnfundedAmount,
    maxTransactionAmount: data?.variables?.maxTransactionAmount,
    maxWithdrawalAmount: data?.variables?.maxWithdrawalAmount,
    maxDepositAmount: data?.variables?.maxDepositAmount,
    fx: data?.variables?.fxToBase,
    spotFx: data?.spots?.[0]?.fx,
    currency,
    isLoading: !error && !data,
    isError: error,
    resetPrice: () => mutate(() => {})
  }
}
