import useSWR from 'swr'
import useAuth from './useAuth.js'

export default function useUser () {
  const { isAuthed, authToken, authedFetch } = useAuth()
  const { data, error, mutate } = useSWR(isAuthed ? ['/api/user', authToken] : null)

  const updateUser = async (user, account, revalidate = true) => {
    const body = {
      User: Object.assign({}, data?.User || {}, user),
      Account: Object.assign({}, data?.Account || {}, account)
    }
    const response = await authedFetch('/api/user', {
      method: 'POST',
      body: JSON.stringify(body)
    })
    mutate(body, revalidate)
    return response
  }

  return { user: data?.User, account: data?.Account, isLoading: !data && !error, updateUser }
}
