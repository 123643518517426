import style from './Nav.module.css'
import Link from 'next/link'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import Updown from '../Updown'
import { useLivePrice, defaultCurrency } from '../../hooks/usePrices'
import resolveProductUnit, { defaultProductUnit } from '../../helpers/resolveProductUnit'
import { createLocalStorageStateHook } from 'use-local-storage-state'
import useAuth from '../../hooks/useAuth'
import useUser from '../../hooks/useUser'
import Router from 'next/router'

const useCurrencyState = createLocalStorageStateHook('fiatCurrency', defaultCurrency)
const useProductUnitState = createLocalStorageStateHook('productUnit', defaultProductUnit)

export default function Nav () {
  const [t, i18n] = useTranslation('main')
  const locale = i18n.language
  const c = classNames.bind(style)
  const { isAuthed, isTwoFaEnabled, setAuth } = useAuth()
  const { user } = useUser()

  const [productUnit] = useProductUnitState()
  const { productPriceResolution, productUnitShortLabel } = resolveProductUnit(productUnit, 1)
  const [currency] = useCurrencyState()
  const { price, updown } = useLivePrice(currency, 'D', productPriceResolution)

  useEffect(() => document.querySelectorAll(`.${style.category} li a`).forEach(a =>
    a.classList[a.href === window.location.toString() ? 'add' : 'remove'](style.active)))

  useEffect(() => document.querySelectorAll(`.${style.category} li a`).forEach(a =>
    a.addEventListener('click', () => { a.blur(); a.classList.add(style.active) })), [])

  const handleInstallClick = e => {
    if (window.deferredInstallPrompt) {
      e.preventDefault()
      e.stopPropagation()
      Router.push('/install')
    }
  }

  const isStandalone = typeof window !== 'undefined' && window.isStandalone

  return (
    <nav className={c('nav')}>
      <Link href={isAuthed ? '/portfolio' : '/sg'}>
        <a>
          <img src='/images/logo-icon-gold.svg' alt={t('nav-homepage', 'Homepage')} className={c('desktop')} />
        </a>
      </Link>
      <div className={c('column')}>
        <div className={c('category')}>
          <Link href='/sg'>
            <a>
              <h5>{t('nav-category-introduction', 'Introduction')}</h5>
            </a>
          </Link>
          <ul>
            <li>
              <Link href='/sg'>
                <a>
                  <h6>{t('nav-home', 'Homepage')}</h6>
                </a>
              </Link>
            </li>
            <li>
              <Link href='/sg/tour'>
                <a>
                  <h6>{t('nav-home', 'Take the tour')}</h6>
                  <span>{t('nav-home-description', 'Get to know the Gold Savings Account.')}</span>
                </a>
              </Link>
            </li>
            <li>
              <Link href='/explore'>
                <a>
                  <h6>{t('nav-explore', 'Explore our gold backing')}</h6>
                  <span>{t('nav-explore-description', 'Always 100% backed by fine gold')}</span>
                </a>
              </Link>
            </li>
            <li>
              <Link href='/sg/pricing'>
                <a>
                  <h6>{t('nav-pricing', 'Pricing & limits')}</h6>
                  <span>{t('nav-pricing-description', 'Protect your wealth affordably')}</span>
                </a>
              </Link>
            </li>
            <li>
              <Link href='/sg/faq'>
                <a>
                  <h6>{t('nav-faq', 'FAQ')}</h6>
                  <span>{t('nav-faq-description', 'Frequently asked questions')}</span>
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className={c('category')}>
          <Link href='/sg/why-should-i-save-in-gold'>
            <a>
              <h5>{t('nav-category-learn', 'Learning')}</h5>
            </a>
          </Link>
          <ul>
            <li>
              <Link href='/sg/singapore-guide-to-gold-savings-and-gold-investments'>
                <a>
                  <h6>{t('nav-singaporeguide', 'The Singapore Guide to Gold Investments')}</h6>
                  <span>{t('nav-singaporeguide-description', 'How do you best expose your portfolio to gold?')}</span>
                </a>
              </Link>
            </li>
            <li>
              <Link href='/sg/why-should-i-save-in-gold'>
                <a>
                  <h6>{t('nav-savingingold', 'Saving in gold')}</h6>
                  <span>{t('nav-savingingold-description', 'When it comes to wealth protection there is nothing like gold')}</span>
                </a>
              </Link>
            </li>
            {/* <li>
              <Link href='/sg/gold-vs-real-estate'>
                <a>
                  <h6>{t('nav-goldvsrealestate', 'Gold vs real-estate')}</h6>
                  <span>{t('nav-goldvsrealestate-description', 'Comparing gold against real-estate for long term wealth protection')}</span>
                </a>
              </Link>
            </li> */}
            <li>
              <Link href='/sg/gold-versus-inflation'>
                <a>
                  <h6>{t('nav-goldinflation', 'Gold vs inflation')}</h6>
                  <span>{t('nav-goldinflation-description', 'Protect yourself against inflation with savings in gold')}</span>
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className={c('category')}>
          <Link href='/sg/about'>
            <a>
              <h5>{t('nav-category-about', 'About')}</h5>
            </a>
          </Link>
          <ul>
            <li>
              <Link href='/sg/about'>
                <a>
                  <h6>{t('nav-about', 'About the Gold Savings Account')}</h6>
                  <span>{t('nav-about-description', 'Learn more about the people behind this service')}</span>
                </a>
              </Link>
            </li>
            <li>
              <Link href='/sg/partners'>
                <a>
                  <h6>{t('nav-partners', 'Our partners')}</h6>
                  <span>{t('nav-partners-description', 'Decades of industry experience')}</span>
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className={c('category', 'mobile')}>
          <h5>{t('nav-resources', 'Resources')}</h5>
          <ul>
            <li className={c('sub')}>
              <Link href='/sg/terms'>
                <a>{t('nav-terms', 'Terms & conditions')}</a>
              </Link>
            </li>
            <li className={c('sub')}>
              <Link href='/sg/privacy'>
                <a>{t('nav-privacy', 'Privacy policy')}</a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={c('column')}>
        <div className={c('category')}>
          <Link href='/price'>
            <a>
              <h5>{t('nav-category-market', '24-hour Gold')}</h5>
              {price?.value !== undefined &&
                <p className={c('price')}>
                  ${price.value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currency}/{productUnitShortLabel} ({updown > 0 ? '+' : ''}{(updown / price.value).toLocaleString(locale, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })}<Updown number={updown} />)
                </p>}
            </a>
          </Link>
          <ul>
            <li>
              <Link href='/price'>
                <a>
                  <h6>{t('nav-prices', 'Live price & chart')}</h6>
                </a>
              </Link>
            </li>
            <li>
              <Link href='/news'>
                <a>
                  <h6>{t('nav-news', 'Gold news')}</h6>
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className={c('category', 'account', { highlight: !user?.FirstName })}>
          <Link href='/portfolio'>
            <a>
              <h5>{t('nav-category-account', 'Your Account')}</h5>
            </a>
          </Link>
          {isAuthed && isTwoFaEnabled
            ? (
              <>
                {user && <p className={c('desktop')}>{user?.FirstName}</p>}
                {user && <p className={c('mobile')}>{t('nav-loggedinas', 'Logged in as')} {user?.FirstName + ' ' + user?.LastName}</p>}
                <ul>
                  <li>
                    <Link href='/portfolio'>
                      <a>
                        <h6>{t('nav-portfolio', 'Your portfolio')}</h6>
                      </a>
                    </Link>
                  </li>
                  <li className={c('highlight')}>
                    <Link href='/buy'>
                      <a>
                        <h6>{t('nav-buyandsell', 'Buy & sell gold')}</h6>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href='/deposit'>
                      <a>
                        <h6>{t('nav-depositandwithdraw', 'Deposit & withdraw cash')}</h6>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href='/sg/redeem'>
                      <a>
                        <h6>{t('nav-redeem', 'Redeem gold')}</h6>
                      </a>
                    </Link>
                  </li>
                  <hr />
                  {!isStandalone && (
                    <li>
                      <Link href='/gettheapp'>
                        <a onClick={handleInstallClick}>
                          <h6>{t('nav-app', 'Install the app')}</h6>
                        </a>
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link href='/share'>
                      <a>
                        <h6>{t('nav-share', 'Share with a friend')}</h6>
                      </a>
                    </Link>
                  </li>
                  <hr />
                  <li>
                    <Link href='/transactions'>
                      <a>
                        <h6>{t('nav-transactionhistory', 'Transaction history')}</h6>
                      </a>
                    </Link>
                  </li>
                  {/* <li>
                    <Link href='/paymentmethods'>
                      <a>
                        <h6>{t('nav-managepaymentmethods', 'Manage Payment Methods')}</h6>
                      </a>
                    </Link>
                  </li>
                  */}
                  <li>
                    <Link href='/settings/profile'>
                      <a>
                        <h6>{t('nav-account', 'Profile settings')}</h6>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href='/settings/security'>
                      <a>
                        <h6>{t('nav-account', 'Account security')}</h6>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <a href='#' onClick={e => { e.preventDefault(); setAuth() }}>
                      <h6>{t('nav-logout', 'Logout')}</h6>
                    </a>
                  </li>
                </ul>
              </>
            ) : (
              <div>
                <ul>
                  <li className={c('highlight')}>
                    <Link href='/signup'>
                      <a>
                        <h6>{t('nav-signup', 'Sign up for a new account')}</h6>
                        <span>{t('nav-signup-description', 'Fully backed by real gold, safe, simple and transparent')}</span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href='/login'>
                      <a>
                        <h6>{t('nav-login', 'Login')}</h6>
                      </a>
                    </Link>
                  </li>
                  <hr />
                  {!isStandalone && (
                    <li>
                      <Link href='/gettheapp'>
                        <a onClick={handleInstallClick}>
                          <h6>{t('nav-app', 'Install the app')}</h6>
                        </a>
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link href='/share'>
                      <a>
                        <h6>{t('nav-share', 'Share with a friend')}</h6>
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
        </div>
        <div className={c('category', 'mobile')}>
          <ul>
            <li className={c('sub')}>
              <a href='tel:+6596617123'>{t('nav-phone', 'Call us at')} <x-nowrap>+65 9661 7123</x-nowrap></a>
            </li>
            <li className={c('sub')}>
              <Link href='/sg/contact'>
                <a>{t('nav-contact', 'Contact us')}</a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
