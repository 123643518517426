import useAuth from './useAuth'
import { createLocalStorageStateHook } from 'use-local-storage-state'

const relayingParty = 'goldsavingsaccount.sg'
const localStorageKey = 'wa'
const useCredential = createLocalStorageStateHook(localStorageKey)

const stringToBuffer = str => {
  return new Uint8Array(str, c => c.charCodeAt(0))
}

const decodeJSONBuffer = buffer => {
  return JSON.parse(String.fromCharCode.apply(null, decodeArrayBuffer(buffer)))
}

const decodeArrayBuffer = buffer => {
  return new Uint8Array(buffer).reduce((a, v) => a.push(v) && a, [])
}

const checkAvailability = async () => {
  return typeof window !== 'undefined' && await window.PublicKeyCredential?.isUserVerifyingWebAuthenticatorAvailable?.()
}

const getChallenge = async () => {
  const response = await window.fetch('/api/webauth')
  if (response.status !== 200) {
    throw new Error('WebAuth not available')
  }
  const text = await response.text()
  return stringToBuffer(text)
}

export default function useWebAuth () {
  const [credential, setCredential] = useCredential({})
  const { authedFetch, setAuth } = useAuth()

  const authenticate = async () => {
    try {
      const publicKeyCredential = await navigator.credentials.get({
        publicKey: {
          challenge: await getChallenge(),
          allowCredentials: [{
            type: 'public-key',
            id: new Uint8Array(credential.id),
            transports: ['internal']
          }]
        }
      })
      const response = await window.fetch('/api/webauth', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'error',
        body: JSON.stringify({
          id: publicKeyCredential.id,
          clientData: decodeJSONBuffer(publicKeyCredential.response.clientDataJSON),
          authenticatorData: decodeArrayBuffer(publicKeyCredential.response.authenticatorData),
          signature: decodeArrayBuffer(publicKeyCredential.response.signature),
          userHandle: decodeArrayBuffer(publicKeyCredential.response.userHandle)
        })
      })
      if (response.status !== 200) {
        throw new Error('WebAuth not available')
      }
      const json = await response.json()
      setAuth(json)
    } catch (err) {
      console.error(err)
    }
  }

  const enableWebAuth = async ({ userName, userId, displayName }) => {
    try {
      const publicKeyCredential = await navigator.credentials.create({
        publicKey: {
          rp: { name: relayingParty },
          user: {
            name: userName,
            id: stringToBuffer(userId),
            displayName: displayName
          },
          pubKeyCredParams: [{ type: 'public-key', alg: -7 }],
          challenge: await getChallenge(),
          authenticatorSelection: { authenticatorAttachment: 'platform' }
        }
      })
      console.log('cred', publicKeyCredential)
      const response = await authedFetch('/api/webauth', {
        method: 'POST',
        body: JSON.stringify({
          id: publicKeyCredential.id,
          clientData: decodeJSONBuffer(publicKeyCredential.response.clientDataJSON),
          attestationObject: decodeArrayBuffer(publicKeyCredential.response.attestationObject)
        })
      })
      if (response.status !== 200) {
        throw new Error('WebAuth not available')
      }
      setCredential({ id: decodeArrayBuffer(publicKeyCredential.rawId), name: displayName })
    } catch (err) {
      console.error(err)
    }
  }

  const disableWebAuth = async () => {
    try {
      const publicKeyCredential = await navigator.credentials.get({
        publicKey: {
          challenge: await getChallenge(),
          allowCredentials: [{
            type: 'public-key',
            id: new Uint8Array(credential.id),
            transports: ['internal']
          }]
        }
      })
      const response = await authedFetch('/api/webauth', {
        method: 'DELETE',
        body: JSON.stringify({
          id: publicKeyCredential.id,
          clientData: decodeJSONBuffer(publicKeyCredential.response.clientDataJSON),
          authenticatorData: decodeArrayBuffer(publicKeyCredential.response.authenticatorData),
          signature: decodeArrayBuffer(publicKeyCredential.response.signature),
          userHandle: decodeArrayBuffer(publicKeyCredential.response.userHandle)
        })
      })
      if (response.status !== 200) {
        throw new Error('WebAuth not available')
      }
      setCredential({})
    } catch (err) {
      console.error(err)
    }
  }

  const webAuthAvailable = checkAvailability()

  return {
    enableWebAuth,
    disableWebAuth,
    authenticate,
    webAuthName: credential?.name,
    webAuthEnabled: webAuthAvailable && !!credential?.id,
    webAuthAvailable
  }
}
