import i18next from 'i18next'

export const defaultProductUnit = 'gram'

export const productUnitRates = {
  gram: 1,
  ounce: 31.1034768
}

export default function resolveProductUnit (productUnit, amount = 1, factor = 1) {
  const productPriceResolution = (productUnitRates[productUnit] || productUnitRates.gram) * factor

  let productUnitLabel = i18next.t('product-unit-gram', { count: amount, defaultValue: 'gram', defaultValue_plural: 'grams' })
  let productUnitShortLabel = i18next.t('product-unit-gram-short', 'g')
  let productUnitFractionDigits = 2
  switch (productUnit) {
    case 'ounce':
      productUnitLabel = i18next.t('product-unit-ounce', { count: amount, defaultValue: 'troy ounce', defaultValue_plural: 'troy ounces' })
      productUnitShortLabel = i18next.t('product-unit-ounce-short', 'toz')
      productUnitFractionDigits = 3
      break
  }

  return { productUnitLabel, productUnitShortLabel, productPriceResolution, productUnitFractionDigits }
}
