import '../styles/global.css'
import * as Sentry from '@sentry/node'
import { Integrations } from '@sentry/tracing'
import i18n from 'i18next'
import { setI18n } from 'react-i18next'
import i18nDetector from 'i18next-browser-languagedetector'
// import i18nHTTPBackend from 'i18next-http-backend'
// import i18nFSBackend from 'i18next-fs-backend'
import { NextNextProvider } from 'i18nextnext'
import Head from 'next/head'
import useAuth from '../hooks/useAuth'
import { SWRConfig } from 'swr'
import Login from '../components/Login'
import Router from 'next/router'
import { pageview } from '../helpers/analytics'

// Setup Sentry
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.05
  })
}

// Hookup Google Analytics PageView event
Router.events.on('routeChangeComplete', (url) => pageview(url))

// Setting up I18n provider
i18n.use(i18nDetector).init({
  lng: 'en-SG',
  languages: ['en'],
  react: { useSuspense: false }
})
// This is code in preparation of supporting more languages than the code native english
// const isNode = typeof window === 'undefined'
// if (isNode) {
//   i18n.use(i18nFSBackend).init({
//     fallbackLng: 'en-SG',
//     ns: ['main'],
//     defaultNS: 'main',
//     backend: {
//       loadPath: 'public/locales/{{lng}}/{{ns}}.json'
//     },
//     react: { useSuspense: false }
//   })
// } else {
//   i18n.use(i18nHTTPBackend).use(i18nDetector).init({
//     fallbackLng: 'en-SG',
//     ns: ['main'],
//     defaultNS: 'main',
//     backend: {
//       loadPath: 'locales/{{lng}}/{{ns}}.json'
//     },
//     react: { useSuspense: false }
//   })
// }
setI18n(i18n)

export default function App ({ Component, pageProps, err }) {
  const { isAuthed, isTwoFaEnabled, authedFetch } = useAuth()
  const hideSSR = typeof window === 'undefined' && Component.requiresAuth

  if (Component.requiresAuth && !(isAuthed && isTwoFaEnabled)) {
    Component = Login
  }

  const getLayout = Component.getLayout || (page => page)

  return (
    <>
      <SWRConfig value={{ fetcher: (...args) => authedFetch(...args).then(res => res.json()) }}>
        <NextNextProvider i18n={i18n}>
          {getLayout(
            <>
              <Head>
                {typeof window !== 'undefined' && (document.body.offsetWidth <= 375 ? <meta key='min' name='viewport' content='width=375, viewport-fit=cover' /> : <meta key='device' name='viewport' content='width=device-width, initial-scale=1, viewport-fit=cover' />)}
                {process.env.NODE_ENV === 'production' && typeof window !== 'undefined' && window.self === window.top && <script id='ze-snippet' src='https://static.zdassets.com/ekr/snippet.js?key=af427d04-188a-4e52-94e1-cfd93f938345' />}
                <meta property='og:title' key='og:title' content='Get 100mg of FREE gold - Gold Savings Account' />
                <meta property='og:url' key='og:url' content='https://goldsavingsaccount.sg' />
                <meta property='og:image' key='og:image' content='https://goldsavingsaccount.sg/images/og_goldsavingsaccount.png' />
                <meta property='og:video' key='og:video' content='https://goldsavingsaccount.sg/assets/goldsavingsaccount_intro.mp4' />
                <meta property='og:video:height' key='og:video:height' content='720' />
                <meta property='og:video:width' key='og:video:width' content='1280' />
                <meta property='og:video:type' key='og:video:type' content='video/mp4' />
                <meta property='og:description' key='og:description' content='The safest and most trusted way to save in gold online. Gold gained annualised more than 8% since 1970 and excels in times of uncertainty. Learn how gold can protect your savings.' />
                <meta property='og:type' key='og:type' content='website' />
                <meta property='og:site_name' key='og:site_name' content='Gold Savings Account' />
                <meta name='title' key='title' content='Get 100mg of FREE gold - Gold Savings Account' />
                <meta name='description' key='description' content='The safest and most trusted way to save in gold online. Gold gained annualised more than 8% since 1970 and excels in times of uncertainty. Learn how gold can protect your savings.' />
              </Head>
              {!hideSSR && <Component {...pageProps} err={err} />}
            </>
          )}
        </NextNextProvider>
      </SWRConfig>
    </>
  )
}
